import React from "react";
import { useNavigate } from "react-router-dom";
import HLines from "../Commons/HLines";
import "./SideDrawer.less";

const SideDrawer = (props) => {
  let navigate = useNavigate();

  let drawerClasses = "side_drawer";
  if (props.show) {
    drawerClasses = "side_drawer show";
  }
  return (
    <div className={drawerClasses} onClick={() => props.closeHandler()}>
      <div className="side_drawer_items">
        <span className="side_drawer_item">
          <div
            className={window.location.pathname === "/" ? "active" : ""}
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
              props.closeHandler();
            }}
          >
            HOME
          </div>
        </span>
        <HLines />
        <span className="side_drawer_item">
          <div
            className={
              window.location.pathname === "/our-html5-games-portfolio" ? "active" : ""
            }
            onClick={() => {
              navigate("/our-html5-games-portfolio");
              window.scrollTo(0, 0);
              props.closeHandler();
            }}
          >
            CASUAL GAMES
          </div>
        </span>
        <HLines />
        <span className="side_drawer_item">
          <div
            className={
              window.location.pathname === "/kids.freakxgames.com" ? "active" : ""
            }
            onClick={() => {
             // navigate("/kids-e-learning-games");
             window.open("https://kids.freakxgames.com", "_blank");
              window.scrollTo(0, 0);
              props.closeHandler();
            }}
          >
            KIDS E-LEARNING
          </div>
        </span>
        <HLines />
        <span className="side_drawer_item">
          <div
            className={window.location.pathname === "/about-us" ? "active" : ""}
            onClick={() => {
              navigate("/about-us");
              window.scrollTo(0, 0);
              props.closeHandler();
            }}
          >
            ABOUT US
          </div>
        </span>
        <HLines />
        <span className="side_drawer_item">
          <div
            className={
              window.location.pathname === "/license-html5-games" ? "active" : ""
            }
            onClick={() => {
              navigate("/license-html5-games");
              window.scrollTo(0, 0);
              props.closeHandler();
            }}
          >
            LICENSING
          </div>
        </span>
        <HLines />
        <span className="side_drawer_item">
          <div
            className={
              window.location.pathname === "/casual-gaming-solution" ? "active" : ""
            }
            onClick={() => {
              navigate("/casual-gaming-solution");
              window.scrollTo(0, 0);
              props.closeHandler();
            }}
          >
            REVENUE SHARE
          </div>
        </span>
        <HLines />
        <span className="side_drawer_item">
          <div
            className={window.location.pathname === "https://blog.freakxgames.com/" ? "active" : "https://blog.freakxgames.com/"}
            onClick={() => {
              window.open("https://blog.freakxgames.com/");
              props.closeHandler();
            }}
          >
            BLOG
          </div>
        </span>
        <HLines />
        <span className="side_drawer_item">
          <div
            className={window.location.pathname === "/careers" ? "active" : ""}
            onClick={() => {
              navigate("/careers");
              window.scrollTo(0, 0);
              props.closeHandler();
            }}
          >
            CAREER
          </div>
        </span>
        <HLines />
        <span className="side_drawer_item">
          <div
            className={window.location.pathname === "/contact-us" ? "active" : ""}
            onClick={() => {
              navigate("/contact-us");
              // window.open('/contact-us', '_blank')
              window.scrollTo(0, 0);
              props.closeHandler();
            }}
          >
            CONTACT US
          </div>
        </span>
        <HLines />
      </div>
    </div>
  );
};

export default SideDrawer;
