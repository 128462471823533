import React from "react";

import PageOne from "./Pages/PageOne";
import PageTwo from "./Pages/PageTwo";
// import PageThree from "./Pages/PageThree";
// import PageFour from "./Pages/PageFour";
// import PageFive from "./Pages/PageFive";
// import PageSix from "./Pages/PageSix";
// import PageSeven from "./Pages/PageSeven";

import { HeaderSize } from "../../Constants/global";

const Contacts = () => {
  return (
    <div style={{ paddingTop: HeaderSize.BottomPad, zIndex: 7 }}>
      <PageOne />
      <iframe
        style={{ width: "100%", height: "475px", border: "none" ,  }}
        src="https://beta-api.gameskite.com/adv/gameIframe.html?partner=portal&showBackButton=false"
        title="Game Iframe" scrolling="no"
      ></iframe>
      {/* <PageTwo /> */}
     
      {/* <PageThree /> */}
      {/* <PageFour /> */}
      {/* <PageFive /> */}
      {/* <PageSix /> */}
      {/* <PageSeven /> */}
    </div>
  );
};

export default Contacts;